import React from "react";
import Header from "../common/header";
import PackageDetails from "./packagedetails/packagedetails";
import Footer from "../common/footer";
import "../packages/packagespage.css";
import { Helmet } from "react-helmet";
function PackagesPage() {
  return (
    <div className="pacakages-page-container">
      <Helmet>
        {/* Standard metadata tags */}
        <title>{"travel and tours"}</title>
        <meta name="description" content={"travel and tours"} />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content={"travel and tours"} />
        <meta property="og:title" content={"travel and tours"} />
        <meta property="og:description" content={"travel and tours"} />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta name="twitter:creator" content={"travel and tours"} />
        <meta name="twitter:card" content={"travel and tours"} />
        <meta name="twitter:title" content={"travel and tours"} />
        <meta name="twitter:description" content={"travel and tours"} />
        {/* End Twitter tags */}
      </Helmet>
      <Header />
      <PackageDetails />
      <Footer />
    </div>
  );
}

export default PackagesPage;
