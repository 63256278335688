import React from 'react'
import './bannerSecond.css'
import { CURRENCY_CODE } from '../../cardsContent'

const BannerSecond = ({title, subTitle, static_price}) => {
  return (
    
        <div className="banner_container">
          <div className="banner_content">
            <span>{subTitle}</span>
            <h1> {title}</h1>
          </div>
          {subTitle=="Tour Packages"?         
           <div className="banner_subcontainer">Minimum group of 6 persons Starting from {CURRENCY_CODE} {static_price?static_price.toLocaleString():'35,000'} per person which includes the following</div>
           :null} 
        </div>
    
  )
}

export default BannerSecond
