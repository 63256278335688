import React from "react";
import Image1 from "../../assets/about.png";
import Image2 from "../../assets/about-icon-1.png";
import Image3 from "../../assets/about-icon-2.png";
import "../aboutsikh/aboutsikh.css";
function AboutSikh() {
  return (
    <div className="aboutsikh-container">
      <div className="row first">
        <div className="col-5">
          <img className="guru" src={Image1}></img>
        </div>
        <div className="col-7 sm-12">
          <div className="sikhabout-content">
            <span>About Sikh Yatra</span>
            <h1 className="main_heading m_b_2">
              WE ARE GURDWARA THAT BELIEVES IN HUMANITY
            </h1>
            <div className="humanity">
              <p style={{ color: "black", fontWeight: "500" }}>
                TDCP is the 1st ever institution providing the services of safe
                & secure tourism under the umbrella of the Punjab Government.
              </p>
            </div>
          </div>
          <div className="row second">
            <div className="another-container">
              <div className="row">
                {" "}
                <div className="image-with-text">
                  <img src={Image2} alt="Image 2" />
                  <div className="text place">
                    <h5>PLACE OF PEACE</h5>
                    <p>
                      A meditation space, where individuals can find inner peace
                      and enjoy a mental or emotional state to feel content,
                      balanced, and tranquil, regardless of their physical
                      surroundings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="another-container2">
              <div className="row">
                <div className="image-with-text">
                  <img src={Image3} alt="Image 3" />
                  <div className="text place">
                    <h5>CONNECTS DEVOTERS</h5>
                    <p>
                      Bringing together individuals who share a common devotion
                      or faith, where devotees can interact, share experiences,
                      and strengthen their spiritual connections.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSikh;
