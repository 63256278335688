import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../../API";
import { useLocation } from "react-router-dom";
import TermsPopup from "./TermsPopup";

const FormInput = ({
  data,
  FinalBooking,
  setFinalBooking,
  membersQty,
  index,
  setmembersQty,
}) => {
  // console.log(membersQty)
  // console.log(FinalBooking.memberData)
  //console.log("data=>", data, index);
  const location = useLocation();
  const { chkIn, chkOut } = location.state;
  const [No_of_persons, SetNo_of_persons] = useState(6);
  const [formData, setFormData] = useState(
    data
      ? data
      : {
          name: "",
          phone: "",
          dob: "",
          passport_authority: "",
          passport_expiry: "",
          picture: "",
          passport_copy: "",
          visa_img: "",
          email: "",
          contact: "",
          nationality: "",
          passport_Number: "",
          No_of_persons: "6",
          passport_issuance: "",
          booking_id: "",
        }
  );
  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear() - 5,
      today.getMonth(),
      today.getDate()
    );
    return maxDate.toISOString().split("T")[0]; // Format the date as yyyy-mm-dd
  };

  //handling Multiple Pictures
  const uploadImage = async (file, name) => {
    //console.log(images[key]);
    const formData = new FormData();
    formData.append("pfile", file);
    try {
      const responce = await axios.post(`${url}/createLink`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (responce.data.success) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: responce.data.success,
        }));
        console.log(responce.data.success);
        alert("Uploading Succesfully");
      }
    } catch (e) {
      alert("Error in Uploading File");
      console.log(e);
    }
  };

  const handleFileChange = (e, imageKey) => {
    const file = e.target.files[0];
    uploadImage(file, imageKey);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addMembersQty = () => {
    console.log(membersQty);

    //console.log(`${FinalBooking.memberData[membersQty - 1]}`);
    //console.log(membersQty, FinalBooking.memberData[membersQty - 1]);
    if (FinalBooking.memberData && FinalBooking.memberData[membersQty - 1]) {
      setmembersQty((prev) => prev + 1);
    } else {
      membersQty === 1
        ? setmembersQty((prev) => prev + 1)
        : alert(
            "Please complete and save the previous form in order to proceed next."
          );
    }
  };
  const add_memebers = (e) => {
    console.log("submit");
    e.preventDefault();
    var selectedmembers = FinalBooking.memberData
      ? FinalBooking.memberData
      : [];
    selectedmembers.push(formData);
    console.log(FinalBooking);
    // alert("Added Succesfully");
    setFinalBooking({ ...FinalBooking, memberData: selectedmembers });
    alert("Member have been saved successfully.");
    console.log(membersQty + " qty " + No_of_persons);
    if (membersQty < No_of_persons) {
      addMembersQty();
    } else {
      alert("Please Click on Confirm Booking");
    }
    //setmembersQty((prev) => prev + 1);
    //setmembersQty(selectedmembers.length)
    // setFormData({
    //   name: "",
    //   phone: "",
    //   dob: "",
    //   passport_authority: "",
    //   passport_expiry: "",
    //   picture: "",
    //   passport_copy: "",
    //   visa_img: "",
    //   email: "",
    //   contact: "",
    //   nationality: "",
    //   passport_Number: "",
    //   No_of_persons: "",
    //   passport_issuance: "",
    //   booking_id: 1,
    // });
    //console.log(FinalBooking);
  };
  return (
    <div className={data ? "m_b_2" : ""}>
      <div className="row">
        <p>
          {index == 0
            ? "PERSONAL INFORMATION (GROUP LEADER)"
            : "PERSONAL INFORMATION"}
        </p>
      </div>
      <form onSubmit={add_memebers}>
        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_input">
              <label htmlFor="fname">Legal Full Name*</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="FULL NAME"
                required
              />
            </div>
          </div>
          <div className="form_grid_2">
            <div className="form_input">
              <label htmlFor="email">Email*</label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="abc@gmail.com"
                required
              />
            </div>
          </div>
        </div>

        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_input">
              <label htmlFor="phoneno">Phone Number*</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="#######"
                required
              />
            </div>
          </div>
          <div className="form_grid_2">
            <div className="form_input">
              <label htmlFor="emergencyno">Emergency Contact No*</label>
              <input
                type="text"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                placeholder="#######"
                required
              />
            </div>
          </div>
        </div>

        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_input">
              <label htmlFor="dob">Date of Birth*</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={formData.dob}
                max={getMaxDate()} // Set the maximum date using a function
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form_grid_2">
            <div className="form_input">
              <label htmlFor="nationality">Nationality*</label>
              <select
                id="nationality"
                name="nationality"
                value={formData.nationality}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled selected hidden>
                  Select Nationality
                </option>
                {[
                  "Germany",
                  "France",
                  "Italy",
                  "Czechia",
                  "Spain",
                  "Austria",
                  "Hungary",
                  "Belgium",
                  "Netherlands",
                  "Greece",
                  "Croatia",
                  "Poland",
                  "Finland",
                  "Portugal",
                  "Romania",
                  "Slovenia",
                  "Denmark",
                  "Sweden",
                  "Slovakia",
                  "Bulgaria",
                  "Latvia",
                  "Estonia",
                  "Luxembourg",
                  "Lithuania",
                  "Australia",
                  "Malaysia",
                  "Indonesia",
                  "America",
                  "Uk",
                  "Canada",
                ]
                  .sort()
                  .map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_input">
              <label htmlFor="issuance">Passport Issuance Authority*</label>
              <input
                type="text"
                id="issuance"
                name="passport_authority"
                value={formData.passport_authority}
                onChange={handleInputChange}
                placeholder=""
                required
              />
            </div>
          </div>
          <div className="form_grid_2">
            <div className="form_input">
              <label htmlFor="passportno">Passport Number*</label>
              <input
                type="text"
                id="passportno"
                name="passport_Number"
                value={formData.passport_Number}
                onChange={handleInputChange}
                placeholder=""
                required
              />
            </div>
          </div>
        </div>

        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_grid">
              <div className="form_grid_1">
                <label for="start" style={{ width: "123px" }}>
                  Passport Issuance
                </label>
                <input
                  type="date"
                  id="start"
                  name="passport_issuance"
                  value={formData.passport_issuance}
                  onChange={handleInputChange}
                  max={new Date().toJSON().slice(0, 10)}
                  required
                />
              </div>
              <div className="form_grid_2">
                <label for="start" style={{ marginLeft: "30px" }}>
                  Expiry Date*
                </label>
                <input
                  type="date"
                  id="end"
                  name="passport_expiry"
                  value={formData.passport_expiry}
                  onChange={handleInputChange}
                  min={chkOut}
                  required
                />
              </div>
            </div>
          </div>
          {index === 0 && (
            <div className="form_grid_2">
              <div className="form_input">
                <label for="persons">No of Persons (Minimum 6)*</label>
                <input
                  type="number"
                  id="persons"
                  name="No_of_persons"
                  //   value={No_of_persons}
                  value={formData.No_of_persons}
                  onChange={(e) => {
                    handleInputChange(e);
                    SetNo_of_persons(e.target.value);
                  }}
                  //onChange={handleInputChange}
                  ///  onChange={(e) => SetNo_of_persons(e.target.valueAsDate)}
                  min={6}
                  //required
                />
              </div>
            </div>
          )}
        </div>

        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_grid">
              <div className="form_input">
                <label for="passportimage">Upload Image*</label>
                <input
                  type="file"
                  className="p-0"
                  id="picture"
                  name="picture"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "picture")}
                  required
                  placeholder="Passport size image"
                />
              </div>
              <div className="form_input">
                <img
                  src={formData.picture || "/assets/placeholder.jpg"}
                  width={50}
                  height={50}
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_grid">
              <div className="form_input">
                <label for="scan-copy">Upload Visa*</label>
                <input
                  type="file"
                  className="p-0"
                  id="visa_img"
                  name="visa_img"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "visa_img")}
                  required
                  placeholder="Scan copy of Passport"
                />
              </div>
              <div className="form_input">
                <img
                  src={formData.visa_img || "/assets/placeholder.jpg"}
                  width={50}
                  height={50}
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="form_grid">
          <div className="form_grid_1">
            <div className="form_grid">
              <div className="form_input">
                <label for="visa-image">Upload Passport*</label>
                <input
                  className="p-0"
                  type="file"
                  id="visa-image"
                  name="passport_copy"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "passport_copy")}
                  required
                  placeholder="Scan copy of Passport"
                />
              </div>
              <div className="form_input">
                <img
                  src={formData.passport_copy || "/assets/placeholder.jpg"}
                  width={50}
                  height={50}
                ></img>
              </div>
            </div>
          </div>
        </div>
        {membersQty - 1 !== index ? null : (
          <div className="other-members">
            <button type="submit">SAVE GROUP MEMBERS</button>
            <button type="button" onClick={() => addMembersQty()}>
              ADD DETAILS OF OTHER GROUP MEMBERS +
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default FormInput;
