import React, { useEffect, useState } from "react";
import { generateSessionId } from "../../../server";
import { useLayoutEffect } from "react";

const PaymetGateway = ({
  paymentResponse,
  setpaymentResponse,
  closeModal,
  dollar,
}) => {
  const [loading, setLoading] = useState(true);
  const [sessionId, setsessionId] = useState("");
 let check =0;
  const initiateCheckOut = async () => {
    console.log("checkOUT")
    try {
      const response = await generateSessionId(
        paymentResponse.id,
        paymentResponse.total,
        dollar
      );
      console.log(response);
      if (response.status == 200) {
        setsessionId(response);
      }
    } catch (error) {
      console.error("Error initiating checkout:", error);
      alert("Something went wrong. Please contact support!");
      closeModal();
    }
  };

  useEffect(() => {
    console.log("sessionIOD=>",sessionId, check);
    if (sessionId == "" && check==0) {
      check=1
      initiateCheckOut();
    }
  }, []);

  useLayoutEffect(() => {
    if (sessionId !== "") {
      console.log("useEffect is running");
      if (sessionId && sessionId.data && sessionId.data["session.id"]) {
        window.Checkout.configure({
          session: {
            id: sessionId.data["session.id"],
          },
        });

        setTimeout(() => {
          window.Checkout.showEmbeddedPage("#embed-target");
          setLoading(false);
        }, 1000);
      } else {
        alert("Something went wrong. Please contact support!");
        closeModal();
      }

      return () => {
        console.log("useEffect cleanup");
        // setsessionId("");
        //setLoading(false);
      };
    }
  }, [sessionId]);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}
      <div id="embed-target"></div>
    </div>
  );
};

export default PaymetGateway;
