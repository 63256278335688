import React, { useState } from "react";
import Modal from "react-modal";
import { terms } from "../../../cardsContent";
import Image3 from "../../assets/tick.svg";
import { RxCross1 } from "react-icons/rx";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "40%",
    transform: "translate(-50%, -50%)",
    height: "70%",
    overflow: "hidden",
  },
};
const TermsPopup = () => {
  const [modalIsOpen, setIsOpen] = useState(true);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="pop_modal_header">
        <RxCross1 onClick={closeModal}>x</RxCross1>
      </div>
      <div className="pop_modal_boday2">
        <h1 className="main_heading text-center">Terms & Condition</h1>
        {console.log(terms)}

        {terms.map((c, i) => (
          <div className="terms">
            <img src={Image3}></img>
            <div>{c}</div>
          </div>
        ))}
        <div className="row">
          <button className="proceed-button" onClick={closeModal}>
            Proceed
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TermsPopup;
