import React, { useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';
import Image1 from '../../assets/activity-icon-1.png';
import Image2 from '../../assets/activity-icon-2.png';
import Image3 from '../../assets/activity-icon-4.png';
import Image4 from '../../assets/What-We-Believe-icon-1.png';

import SwiperCore from 'swiper';
import { Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './CustomSliders.css';
import { featuresToursContent } from '../../../cardsContent';
// Initialize SwiperCore modules
SwiperCore.use([Navigation]);


const CustomSliders = () => { 
  useEffect(() => {
    const swiper = new SwiperCore('.swiper-container', {
      slidesPerView: 4, // Allow variable slide widths
      spaceBetween: 0,
      navigation: {
        prevEl: '.custom-prev-arrow',
        nextEl: '.custom-next-arrow',
      },
    });
  }, []);

  return (
  <section className="slider_container main_container">
   <div className='feature-content inner_container'>
    <p>The Sikh Shrines</p>
    <h1 className='m_b_2 main_heading'>Feature of Tours</h1>
   </div>

    <div className="swiper-container">
      <div className="swiper-wrapper">
        {
          featuresToursContent.map((F, i)=>(
            <div className="swiper-slide">
            <div class="slider_card">
                  <img className="slider_card_img mx-auto" src={F.img} alt="Card image cap" />
                  <div className='slider_card_text'>{F.text}</div>
                </div>
            </div>
          ))
        }
        {/* Add more slides here */}
      </div>
      <div className="custom-next-arrow">
    <FaArrowRight /> 
  </div>
  <div className="custom-prev-arrow">
    <FaArrowLeft /> 
  </div>
    </div>
  </section>
  )
}

export default CustomSliders
