import React from 'react'
import Header from '../common/header';
import GoldenTemple from "../home/goldentemple/goldentemple"
import SikhLearning from './sikhlearning/sikhlearning';
import AboutSikh from '../home/aboutsikh/aboutsikh';
import Footer from '../common/footer';
import "../about/aboutpage.css"
import BannerSecond from '../common/BannerSecond';
function AboutPage() {
  return (
    <div className='About-Container'>
        <Header/>
        <BannerSecond title="About" subTitle="The Sikh Shrines"/>
        <GoldenTemple showNankanaRow={false}/>
        <SikhLearning/>
        <AboutSikh/>
        <Footer/>
    </div>
  )
}

export default AboutPage;