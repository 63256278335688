import React from 'react'
import './resultpage.css'
import Header from '../common/header'
import BookingDetails from './bookingdetails/bookingdetails'
import Banner6 from './banner6/banner6'
import Footer from '../common/footer'
function ResultPage() {
  return (
    <div className='result-page-container'>
        <Header/>
        <Banner6/>
        <BookingDetails/>
        <Footer/>
    </div>
  )
}

export default ResultPage