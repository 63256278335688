import React from "react";
import Header from "../common/header";
import Carousel from "./carousel/carousel";
import Footer from "../common/footer";
import "../gallery/gallerypage.css";
import BannerSecond from "../common/BannerSecond";
import { Helmet } from "react-helmet";
function GalleryPage() {
  return (
    <div className="gallery-page-container">
      <Helmet>
        {/* Standard metadata tags */}
        <title>{"pakistan tour packages"}</title>
        <meta name="description" content={"pakistan tour packages"} />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content={"pakistan tour packages"} />
        <meta property="og:title" content={"pakistan tour packages"} />
        <meta property="og:description" content={"pakistan tour packages"} />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta name="twitter:creator" content={"pakistan tour packages"} />
        <meta name="twitter:card" content={"pakistan tour packages"} />
        <meta name="twitter:title" content={"pakistan tour packages"} />
        <meta name="twitter:description" content={"pakistan tour packages"} />
        {/* End Twitter tags */}
      </Helmet>
      <Header />
      <BannerSecond title="GALLERY" subTitle="The Sikh Shrines" />
      <Carousel />
      <Footer />
    </div>
  );
}

export default GalleryPage;
