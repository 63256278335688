import React, { useEffect, useState } from "react";
import "./bookinginformation.css";
import Image from "../../assets/ticket.svg";
import Image3 from "../../assets/tick.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CURRENCY_CODE,
  cancellationPolicy,
  visaPolicy,
} from "../../../cardsContent";
import FormInput from "./FormInput";
import moment from "moment/moment";
import { toCreateBooking } from "../../../server";
import Modal from "react-modal";
import { RxCross1, RxStarFilled } from "react-icons/rx";
import { CurrencyConversionPkrFn } from "../../../server";
import PaymetGateway from "./PaymetGateway";
import Popup from "./Popup";
import TermsPopup from "./TermsPopup";
import axios from "axios";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "650px",
    transform: "translate(-50%, -50%)",
    maxHeight: "90vh",
  },
};

function BookingInformation({
  FinalBooking,
  setFinalBooking,
  tour,
  bookingType,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [chkbox, setChkbox] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  var pkr = 0;
  const [membersQty, setmembersQty] = useState(1);
  const [tot, setTot] = useState(0);
  var todayRate = 0;
  const [paymentResponse, setpaymentResponse] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const todayDollartoPkr = async () => {
    var pkr = 0;
    const options = {
      method: "GET",
      url: "https://currency-exchange.p.rapidapi.com/exchange",
      params: {
        from: "usd",
        to: "pkr",
        q: 1,
      },
      headers: {
        "X-RapidAPI-Key": "c91ba54bc4msh05dfb03b08db01fp1bbc12jsn82793eeb6ebc",
        "X-RapidAPI-Host": "currency-exchange.p.rapidapi.com",
      },
    };

    try {
      const res = await axios.request(options);
      console.log(res.data);
      setTot(res.data);
      pkr = res.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    todayDollartoPkr();
  }, []);
  const { chkIn, chkOut, packages } = location.state;
  //console.log("data=>", location.state);
  let police_total = 0,
    hotel_total = 0,
    car_total = 0,
    meals_price = 0,
    service_charges = 0,
    static_total = 0;

  const Days = moment(chkOut, "YYYY-MM-DD").diff(
    moment(chkIn, "YYYY-MM-DD"),
    "days"
  );

  const GSTCalculator = (number, percentage) => {
    return parseInt(parseInt(Number(number) * Number(percentage)) / 100);
  };
  const handleTermsClick = (e) => {
    e.preventDefault();
    setShowPopup(!showPopup);
  };
  const FinalSubmissionData = async () => {
    // console.log(FinalBooking, FinalBooking.memberData);
    if (FinalBooking.memberData) {
      //console.log(FinalBooking.memberData.length);
      if (FinalBooking.memberData.length > 0) {
        var Fdata = {
          TourId: tour.id,
          booking_type: bookingType,
          vehicleID: FinalBooking.vehicleID || 0,
          room: hotel_total,
          service_charges: service_charges,
          transport: car_total,
          meals: meals_price,
          meal_name: packages.meal_title,
          meal_gst: packages.meal_gst,
          police_gst: packages.police.length
            ? packages.police[0].police_gst
            : "",
          vehicle_gst: packages.vehicles
            ? packages.vehicles[0].vehicle_gst
            : "",
          reservation_date: moment().format("YYYY/MM/DD"),
          payment_method: "Credit/Debit",
          police: police_total,
          total:
            bookingType !== "static"
              ? car_total +
                police_total +
                hotel_total +
                meals_price * membersQty +
                service_charges
              : static_total + service_charges,
          status: "pending",
          arrival: moment(chkIn, "YYYY-MM-DD").format("YYYY/MM/DD"),
          departure: moment(chkOut, "YYYY-MM-DD").format("YYYY/MM/DD"),
          memberData: FinalBooking.memberData,
          policeService_id: FinalBooking.policeService_id || 0,
          hotelData: FinalBooking.hotelData ? FinalBooking.hotelData : [],
        };
        //console.log(Fdata);

        try {
          const responce = await toCreateBooking(Fdata);
          // console.log(responce);
          if (responce["status"] == 200) {
            setpaymentResponse(responce["data"][0]);
            // openModal();
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        alert("please add atleast 6 members.");
      }
    } else {
      alert("please add members.");
    }
  };

  // Handle form submission logic here
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (chkbox == "") {
      return alert("Please check the terms and conditions checkbox");
    }
    /// setShowPopup(true);
    if (bookingType !== "static") {
      if (FinalBooking.hotelData && FinalBooking.hotelData.length) {
        if (FinalBooking.vehicleID) {
          FinalSubmissionData();
        } else {
          alert("please choose your transport.");
        }
      } else {
        alert("please choose your accomodation.");
      }
    } else {
      FinalSubmissionData();
    }
  };

  useEffect(() => {
    console.log("in");
    if (paymentResponse !== "") {
      if (paymentResponse.id) {
        openModal();
      }
    }
  }, [paymentResponse]);

  return (
    <div className="book-tour-container m_t_2 box_shadow">
      {bookingType == "static" ? <Popup /> : null}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal_header">
          <div className="title">Check Out</div>
          <RxCross1 onClick={closeModal}>x</RxCross1>
        </div>
        <div className="modal_boday">
          <PaymetGateway
            dollar={tot}
            paymentResponse={paymentResponse}
            setpaymentResponse={paymentResponse}
            closeModal={closeModal}
          />
        </div>
      </Modal>

      <div className="banner">
        <img src={Image}></img>
        <span> Booking Information</span>
      </div>

      <div className="hotel-booking">
        <div className="booking-information-subcontainer">
          {Array.from({ length: membersQty }, (_, index) => (
            <FormInput
              data={
                FinalBooking.memberData
                  ? FinalBooking.memberData[index] || ""
                  : ""
              }
              FinalBooking={FinalBooking}
              setFinalBooking={setFinalBooking}
              key={index}
              index={index}
              membersQty={membersQty}
              setmembersQty={setmembersQty}
            />
          ))}

          {/* <FormInput
            FinalBooking={FinalBooking}
            setFinalBooking={setFinalBooking}
          /> */}

          {/* cancellation policy */}
          <div className="row billing-policy">
            <div className="col-md-6 col-sm-12 cancellation-visa px-lg-5 px-3">
              <div className="row">
                <div>
                  {" "}
                  <h1 className="cancellation-policy col-sm-12">
                    Cancellation Policy
                  </h1>
                </div>

                {cancellationPolicy.map((c, i) => (
                  <div className="cancellation">
                    <img src={Image3}></img>
                    <div>{c}</div>
                  </div>
                ))}

                <h1 className="visa-policy">Visa Policy</h1>
                {visaPolicy.map((c, i) => (
                  <div className="cancellation">
                    <img src={Image3}></img>
                    <div>{c}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 col-sm-12 billing-details px-lg-5 px-3">
              <h1 className="billing-details">Billing Details</h1>
              {/* {console.log(FinalBooking)} */}
              {bookingType !== "static" ? (
                <>
                  <div className="accomodation">
                    <h3>ACCOMODATION</h3>
                    {FinalBooking.hotelData
                      ? FinalBooking.hotelData.map((p) => {
                          var hotel_price = Number(p.price) * p.room_quantity;
                          var GST =
                            p.room_quantity *
                            GSTCalculator(p.price, p.hotel_gst);
                          hotel_total += hotel_price + GST;
                          return (
                            <p>
                              {p.room_quantity > 1 ? p.room_quantity : ""}{" "}
                              {p.room_name} for {Days} night @ {CURRENCY_CODE}
                              {hotel_price}/night
                            </p>
                          );
                        })
                      : null}
                    <p className="mt-2">
                      Total (incl. Tax) {CURRENCY_CODE}{" "}
                      {hotel_total.toLocaleString()}
                    </p>
                  </div>
                  <div className="accomodation">
                    <h3>TRANSPORT</h3>
                    {FinalBooking.vehicleID
                      ? FinalBooking.vehicleID.split(",").map((p, i) => {
                          var findPolice = packages.vehicles.find(
                            (pp) => pp.id.toString() == p.toString()
                          );
                          var VechicleQty = FinalBooking.VechicleQty.split(",");
                          var vehicles_price =
                            Number(findPolice.rent * VechicleQty[i]) * Days;
                          car_total += vehicles_price;
                          return findPolice ? (
                            <p>
                              {VechicleQty[i] > 1 ? VechicleQty[i] : ""}{" "}
                              {findPolice.name} for {Days} days @{" "}
                              {CURRENCY_CODE} {vehicles_price}/vehicles
                            </p>
                          ) : null;
                        })
                      : null}
                    <p className="mt-2">
                      Total (incl. Tax) {CURRENCY_CODE}{" "}
                      {(car_total += GSTCalculator(
                        car_total,
                        packages.vehicles[0]["vehicle_gst"]
                      )).toLocaleString()}
                    </p>
                  </div>

                  <div className="accomodation">
                    <h3>POLICE SQUAD</h3>
                    {FinalBooking.policeService_id
                      ? FinalBooking.policeService_id.split(",").map((p, i) => {
                          var findPolice = packages.police.find(
                            (pp) => pp.id.toString() == p.toString()
                          );
                          var policeService_qty =
                            FinalBooking.policeService_qty.split(",");
                          var police_price =
                            Number(findPolice.price * policeService_qty[i]) *
                            Days;
                          police_total += police_price;
                          return findPolice ? (
                            <p>
                              {policeService_qty[i] > 1
                                ? policeService_qty[i]
                                : ""}{" "}
                              {findPolice.category} for {Days} days @{" "}
                              {CURRENCY_CODE} {police_price}/security
                            </p>
                          ) : null;
                        })
                      : null}
                    <p className="mt-2">
                      Total (incl. Tax) {CURRENCY_CODE}{" "}
                      {(police_total += GSTCalculator(
                        police_total,
                        packages.police[0]["police_gst"]
                      )).toLocaleString()}
                    </p>
                  </div>
                  {packages.meal_title ? (
                    <div className="accomodation">
                      <h3>MEAL</h3>
                      <p>
                        {packages.meal_title} for {Days} days @ {CURRENCY_CODE}{" "}
                        {(meals_price = Number(packages.meals_price * Days))}{" "}
                        per person
                      </p>
                      <p className="mt-2">
                        Total (incl. Tax) {CURRENCY_CODE}{" "}
                        {(meals_price += GSTCalculator(
                          meals_price * membersQty,
                          packages["meal_gst"]
                        )).toLocaleString()}{" "}
                        per person
                      </p>
                      <p className="mt-2">
                        Total ({membersQty + " persons"}) {CURRENCY_CODE}{" "}
                        {meals_price * membersQty.toLocaleString()}
                      </p>
                    </div>
                  ) : null}
                </>
              ) : packages.static_price ? (
                <div className="accomodation">
                  <h3>TOUR PRICE</h3>
                  <p>
                    {packages.static_price} for {Days} days @ {CURRENCY_CODE}{" "}
                    {(static_total = packages.static_price)}
                    {/* {FinalBooking.memberData &&
                    FinalBooking.memberData.length > 0
                      ? (static_total =
                          Number(packages.static_price) *
                          FinalBooking.memberData.length)
                      : (static_total = 0)} */}{" "}
                    per person
                  </p>

                  <p className="mt-2">
                    Total (incl. Tax) {CURRENCY_CODE}{" "}
                    {static_total.toLocaleString()}
                  </p>
                  <p className="mt-2">
                    Total ( {membersQty + " persons"}) {CURRENCY_CODE}{" "}
                    {FinalBooking.memberData &&
                    FinalBooking.memberData.length > 0
                      ? (static_total =
                          Number(packages.static_price) *
                          FinalBooking.memberData.length)
                      : (static_total = 0)}
                  </p>
                </div>
              ) : null}

              {packages.service_charges ? (
                <div className="accomodation">
                  <h3>SERVICE CHARGES</h3>

                  <p className="mt-2">
                    Total ({packages.service_charges}%) {CURRENCY_CODE}{" "}
                    {(service_charges += GSTCalculator(
                      parseInt(
                        bookingType !== "static"
                          ? police_total + hotel_total + car_total + meals_price
                          : static_total
                      ),
                      packages.service_charges
                    )).toLocaleString()}
                  </p>
                </div>
              ) : null}

              <div className="total">
                <div>
                  GRAND TOTAL
                  {/* {FinalBooking.memberData && FinalBooking.memberData.length > 0
                    ? FinalBooking.memberData.length
                    : 0}
                  ) Persons */}
                </div>
                {showPopup && <TermsPopup />}
                <div>
                  {CURRENCY_CODE}
                  {bookingType !== "static"
                    ? Number(
                        police_total +
                          hotel_total +
                          car_total +
                          meals_price * membersQty +
                          service_charges
                      ).toLocaleString()
                    : Number(static_total + service_charges).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <p>
              The Amount is presented as USD {" $"}
              {bookingType !== "static"
                ? Number(
                    police_total +
                      hotel_total +
                      car_total +
                      meals_price * membersQty +
                      service_charges
                  ).toLocaleString()
                : Number(static_total + service_charges).toLocaleString()}{" "}
              for indicative purposes. In completing this transsaction, you
              authorise the merchant, Sikh Yatra Tours to complete the
              transaction at today's exchange rate of {Math.trunc(tot)} in the
              amount of PKR{" "}
              {Math.trunc(
                bookingType !== "static"
                  ? Number(
                      police_total +
                        hotel_total +
                        car_total +
                        meals_price * membersQty +
                        service_charges
                    ) * tot.toLocaleString()
                  : Number(static_total + service_charges) *
                      tot.toLocaleString()
              )}
              {" ."}
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <input
                type="checkbox"
                id="email"
                style={{ marginBottom: "10px" }}
                name="email"
                placeholder="abc@gmail.com"
                checked={chkbox}
                required
                onChange={(e) => setChkbox(!chkbox)}
              />
            </div>

            <div style={{ marginLeft: "8px", marginBottom: "10px" }}>
              By ticking,you are confirming that you have read, understood and
              agree to our{" "}
              <a href="" onClick={handleTermsClick}>
                {" "}
                terms and conditions.
              </a>
            </div>
          </div>
          <div className="confirm-booking">
            <button
              type="button"
              className="confirm-booking-button  box_shadow"
              onClick={handleSubmit}
            >
              CONFIRM BOOKING
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingInformation;
