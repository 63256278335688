import React from "react";
import { amenitiesContent } from "../../../cardsContent";

const AmenitiesContainer = ({ title }) => {
  return (
    <div className="package-detail-cards">
      {amenitiesContent.map((a, i) => (
        <div
          className={
            i !== 3
              ? "packagedetails-subcontainer-cards border-right-0"
              : "packagedetails-subcontainer-cards"
          }
          key={i}
        >
          <img src={a.img} alt="Image1" />
          {i == 0 ? (
            <p>
              {title.toLowerCase() == "deluxe" ? "5 " : "4 "}
              {a.title}
            </p>
          ) : (
            <p>{a.title}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default AmenitiesContainer;
