import React, { useEffect, useState } from "react";
import "../packagedetails/packagedetails.css";
import "../../../App.css";
import Image4 from "../../assets/packge.jpg";
import Image5 from "../../assets/tick.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { url } from "../../../API";
import AmenitiesContainer from "./AmenitiesContainer";
import moment from "moment";
import { getPackageDetails } from "../../../server";
import { includesData } from "../../../cardsContent";
import BannerSecond from "../../common/BannerSecond";

function PackageDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { dayId, tour } = location.state;
  const [packages, setPackages] = useState([]);
  const [Daysdetails, setDaysdetails] = useState([]);
  const [chkIn, setChkIn] = useState(moment());
  const [chkOut, setChkOut] = useState(moment().add(Number(dayId), "days"));

  const getPackage = async () => {
    try {
      const p = await getPackageDetails(dayId, tour.id);
      console.log(p);
      if (p["status"] == 200) {
        setPackages(p["data"][0]);
        setDaysdetails(
          p["data"][0]["description"]
            ? p["data"][0]["description"].split("|")
            : []
        );
      } else {
        setPackages([]);
        setDaysdetails([]);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const addDays = (date, day) => {
    const newDate = moment(date).add(day, "days");
    setChkOut(newDate);
  };
  const setBooking = (type) => {
    if (chkIn.format("YYYY-MM-DD") == chkOut.format("YYYY-MM-DD")) {
      alert("please select arrivial");
      return;
    } else {
      navigate("/tourpage", {
        state: {
          dayId: dayId,
          tour: tour,
          packages: packages,
          bookingType: type,
          chkIn: chkIn.format("YYYY-MM-DD"),
          chkOut: chkOut.format("YYYY-MM-DD"),
        },
      });
    }
  };
  useEffect(() => {
    getPackage();
  }, []);
  return (
    <>
      {/* {console.log(packages)} */}
      <BannerSecond
        title={tour.title}
        subTitle="Tour Packages"
        static_price={packages.static_price}
      />
      <div className="main_container_2">
        <div className="inner_container">
          <AmenitiesContainer title={tour.title} />

          <section className="package_section">
            <div className="detail-banner">Package details</div>

            <div className="package-details">
              <div class="card box_shadow">
                <div class="card-body">
                  <div className="timer_card">
                    <div class="timer_card_time">
                      {dayId.toString().padStart(2, "0")}
                    </div>
                    <div className="timer_card_days">Days</div>
                  </div>

                  <h6 class="card-subtitle ">{tour.sub_title}</h6>
                  {Daysdetails.length
                    ? Daysdetails.map((p, i) => {
                        return (
                          <div class="card-text" key={i}>
                            <span className="days">Day {i + 1}:</span>
                            {p}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>

              <div className="booking">
                <div className="detail-container">
                  <div className="arrival-date">
                    <label>Arrival Date</label>
                    <input
                      type="date"
                      name="name"
                      value={chkIn.format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setChkIn(moment(e.target.value));
                        addDays(moment(e.target.value), dayId);
                      }}
                      className="form-control"
                      placeholder="Select Arrival Date"
                    />
                  </div>
                  <div className="arrival-date">
                    <label>Departure Date </label>
                    <input
                      type="date"
                      name="name"
                      value={chkOut.format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setChkOut(moment(e.target.value));
                      }}
                      className="form-control"
                      placeholder="Select Arrival Date"
                    />
                  </div>
                  <button
                    type="Submit"
                    className="box_shadow booknow-button"
                    onClick={() => setBooking("static")}
                  >
                    BOOK TOUR
                  </button>
                  <button
                    type="Submit"
                    className="box_shadow booknow-button"
                    onClick={() => setBooking("custom")}
                  >
                    CUSTOMIZE YOUR TOUR
                  </button>
                  <div
                    style={{ textAlign: "center" }}
                    className="booknow-image"
                  >
                    Hotel of Your Choice | Luxury Transport | Security Addons{" "}
                  </div>

                  <img src={Image4} className="booknow-image box_shadow"></img>
                </div>
              </div>
            </div>
          </section>
          <section className="include-container">
            <h1>Includes</h1>
            {includesData.map((inc, i) => (
              <div className="include-content">
                <img src={Image5}></img>
                <span>{inc}</span>
              </div>
            ))}
          </section>
        </div>
      </div>
    </>
  );
}

export default PackageDetails;
