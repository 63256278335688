import React, { useState } from "react";
import { ReactComponent as Person } from "./person.svg";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import {ReactComponent as ArrowUp} from './arrow-up.svg';
import { CURRENCY_CODE } from "../../../cardsContent";
const RoomModal = ({ Rooms, FinalBooking, setFinalBooking ,ConversionRate}) => {
 // console.log(ConversionRate);
  return Rooms["HotelRooms"].map((r, i) => (
    <div class="room-details-container d-lg-flex d-md-flex justify-content-lg-between justify-content-md-between w-100" key={r["RoomName"]}>
      <div class="room-details-container-left">
        <img src={r["RoomImages"][0]["RoomImage"][0]["$"]["Photo_Max500"]} />
      </div>

      <div class="room-details-container-right">
        <div class="modal-heading mb-2">{r["RoomName"]}</div>
        <div>
          {r["RatePlanDetails"][0]['RatePlans'].map((ratePlan, i) => (
            <RatePlanCard hotel_id={Rooms.AccommodationId[0]} hotel_name={Rooms.AccommodationName[0]}
            hotel_img={Rooms.ImageURL[0]}
            room_id={r['RoomId'][0]}
            room_name={r['RoomName'][0]}
            star={Rooms['star']}
            ConversionRate={ConversionRate}
             ratePlan={ratePlan} key={i} FinalBooking={FinalBooking} setFinalBooking={setFinalBooking}/>
        
          ))}
        </div>
      </div>
    </div>
  ));
};

export const RatePlanCard=({ratePlan,hotel_id,hotel_img,hotel_name, star, room_id,room_name, FinalBooking, setFinalBooking, ConversionRate})=>{
  console.log(ratePlan);
 let rate= ratePlan['RatePlanCurrencyCode']=="PKR"?Number(ratePlan['Rate'][0])*Number(ConversionRate):ratePlan['Rate'][0];
 //console.log(rate)
  return(
  <div class="rate-plan-details mb-3">
  <div class="refud-non-refund">
    <div class="f-14 text-uppercase text-default-bold">
      {ratePlan['RatePlanName'][0]}
    </div>
    <div class="f-13 text-default text-bold">
      {CURRENCY_CODE} {rate} / night
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-end position-relative">
    <div class="drop-down-container me-2">
    {Array.from({ length: ratePlan['MaxPerson'][0] }, (_, index) => (
      <Person key={index} />
      ))}
    </div>
    <RoomSelectBox 
    ratePlan={ratePlan}
    FinalBooking={FinalBooking} 
    setFinalBooking={setFinalBooking} 
    hotel_id={hotel_id} 
    rate={rate}
    star={star} room_name={room_name} room_id={room_id} hotel_name={hotel_name} hotel_img={hotel_img}/>

  </div>
</div>
  )
}

export const RoomSelectBox=({ratePlan,rate,hotel_id,hotel_name,hotel_img,star,room_id,room_name, FinalBooking, setFinalBooking})=>{
  const [isOpen, setIsOpen]=useState(false);
  var selectedHotelArray=FinalBooking.hotelData?FinalBooking.hotelData:[];
  var findData=selectedHotelArray.find(h=>h.hotel_id==hotel_id && h.room_id==room_id && h.rateplan_id==ratePlan['RatePlanId'][0]);
  const [quantity, setquantity]=useState(findData?findData['room_quantity']:0);

  const selectRatePlan=(quantity, ratePlan)=>{
   //console.log(quantity, ratePlan);
    setquantity(quantity);
    setIsOpen(false);
    //console.log(findData);
    if(findData){
      const indexToRemove = selectedHotelArray.indexOf(findData);
      if(quantity==0){
        if (indexToRemove !== -1) {
          selectedHotelArray.splice(indexToRemove, 1);
        }
      }else{
        selectedHotelArray[indexToRemove]['room_quantity']=quantity;
      }
    }else if(quantity !== 0){
      selectedHotelArray.push({  
          "hotel_id": hotel_id,
          "hotel_name": hotel_name,
          "hotel_img": hotel_img,
          "hotel_stars": star,
          "room_id": room_id,
          "price": Number(rate),
          "room_quantity": quantity,
          "rateplan_id": ratePlan['RatePlanId'][0],
          "room_name": room_name,
          "rateplan_name": ratePlan['RatePlanName'][0],
          "hotel_gst": Number(ratePlan['Taxs'][0]['Tax'][0]['$']['TaxValue'])
      })
    }

  setFinalBooking({... FinalBooking, hotelData:selectedHotelArray});
  //console.log(FinalBooking);
  }
return(
<>
<div class="drop-down-room" onClick={()=>setIsOpen(!isOpen)}>
  <div>Rooms</div>
  <div class="drop-down-icon">
    <span>{quantity}</span>
    {isOpen?<ArrowUp/>:<ArrowDown />}
  </div>

  
</div>
{isOpen? 
  <ul className="room_drop_down box_shadow">
    {Array.from({ length: ratePlan['NoOfRoomsAvailable'][0]+1 }, (_, index) => (
    <li onClick={()=>selectRatePlan(index, ratePlan)} key={index}>Room {index}</li>
    ))}
  </ul>:null}
</>
)

}

export default RoomModal;
