import React, { useEffect, useState } from "react";
// import Image1 from '../../assets/Picture2.png';
// import Image2 from '../../assets/Picture3.png';
// import Image3 from '../../assets/Picture4.png';
// import Image4 from '../../assets/Picture5.png';
// import Image5 from '../../assets/Picture6.png';
// import Image6 from '../../assets/Picture7.png';
import "./card.css";
import { getBlogs } from "../../server";
///import { Locations } from "../../../cardsContent";
import "../../App.css";
import { Link } from "react-router-dom";
import Header from "../common/header";
function Blog() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getBlogs().then((data) => setData(data.data));
  }, []);
  const Locations = [
    {
      id: 0,
      img: "/assets/Picture2.png",
      title: "GURDWARA SACHA SAUDA, FAROOQABAD",
      desc: "Sacha Sauda is a village near Chuharkana (now known as Farooqabad) in Punjab, Pakistan. This holy Gurdwara stands massively in the fields on the other side.",
    },
    {
      id: 1,
      img: "/assets/Picture3.png",
      title: "GURDWARA RORI SAHIB, EMINABAD",
      desc: "In the old town of Eminabad, some 15 kms away from Gujranwala , Gurdwara Rori Sahib is located at the site where Guru Nanak is believed to have taken refuge from the pillage of Eminabad by Babur.",
    },
    {
      id: 2,
      img: "/assets/Picture4.png",
      title: "GURDWARA DARBAR SAHIB, KARTARPUR",
      desc: "Located in Kartarpur in the Narowal district of Punjab in Pakistan, it is one of the most important sites of Sikhism along with the Golden Temple and Janam Asthan Sahib.",
    },
    {
      id: 3,
      img: "/assets/Picture5.png",
      title: "GURDWARA PANJA SAHIB, HASAN ABDAL",
      desc: "Located in Hasan Abdal, near the city of Islamabad, the 19th-century Gurdwara attracts thousands of pilgrims every year. It is of particular importance to Sikhs because of the legends associated with it.",
    },
    {
      id: 4,
      img: "/assets/Picture6.png",
      title: "GURDWARA DERA SAHIB, LAHORE",
      desc: "Built at the site of the martyrdom of the 5th Sikh Guru, Guru Arjan Dev, this gurdwara is located just outside the walled city of Lahore. It is a part of an ensemble of historical monuments in Lahore, along with the Lahore Fort.",
    },
    {
      id: 5,
      img: "/assets/Tomb_of_Ranjit_Singh,_Lahore.jpg",
      title: "SAMADHI OF MAHARAJA RANJIT SINGH",
      desc: "Ranjit Singh is famous as Sher-e- Punjab in the history of the subcontinent. He ruled over Punjab from 1799 to 1839 AD. This was built at the place where Sher e Punjab Maharaja Ranjit was cremated.",
    },
  ];
  return (
    <>
      <Header />
      <section className="main_container">
        <div className="inner_container cards">
          <div className="cards_grid">
            {/* {console.log(data)} */}
            {data.map((c, i) => (
              <div class="card">
                <img
                  class="card-img-top blog-img"
                  src={c.image}
                  alt={c.meta_title}
                />
                <div class="card_body">
                  <h5 className="card-title">{c.meta_title}</h5>
                  <p className="card_text">{c.meta_description}</p>
                </div>
                <a className="read_more">
                  <Link
                    to={`/post/${c.post_ID}/${c.meta_title
                      .split(" ")
                      .join("-")}`}
                    state={{ id: c.post_ID || 2 }}
                  >
                    {" "}
                    Read More
                  </Link>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
