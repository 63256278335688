import { Link } from "react-router-dom";
import Header from "./common/header";

export default function NotFound() {
  return (
    <div>
      <Header />
      <div className="setStyle2">
        <p>4O4 NOT FOUND</p>
      </div>
      {/* <div>
        <Link to="/">Home</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/contactpage">Contact</Link>
      </div> */}
    </div>
  );
}
