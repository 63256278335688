import React from "react";
import "../common/footer.css";
import Image1 from "../assets/Picture5.png";
import Image2 from "../assets/about.png";
import Image3 from "../assets/gallery-img-5.png";
import Image4 from "../assets/Tomb_of_Ranjit_Singh,_Lahore.jpg";
import { BiPhoneCall } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaFacebookF, FaGooglePlusG , FaTwitter, FaInstagram} from "react-icons/fa";

function Footer() {
  return (
    <div className="footer">
      <div className="container-3">
        <div className="row gallery">
          <div className="col-md-12 col-lg-7">
            <h5 className="footer-1-content">Photo Gallery</h5>
            <div className="photo-row d-flex">
              <img src={Image1} alt="Image1" />
              <img src={Image3} alt="Image3" />
              <img src={Image2} alt="Image2" />
              <img src={Image4} alt="Image4" />
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="quick-contact mt-4">
              <h5>Quick Contact</h5>
              <a href="tel:+924299231360" className="design-first">
                <BiPhoneCall
                  style={{ color: "#F7921F", marginRight: "10px" }}
                />
                +92 42 99231360
              </a>
              <br />
              <span className="design-second">
                <FaLocationDot
                  style={{ color: "#F7921F", marginRight: "10px" }}
                />
                68, Trade Centre Commercial Area Block M.A Johar Town, Lahore,
                Punjab
              </span>
              <br />
              <a href="mailto:info@tdcp.gop.pk" className="design-third">
                <MdEmail style={{ color: "#F7921F", marginRight: "10px" }} />
                info@tdcp.gop.pk
              </a>
            </div>
            <div className="connect mt-4 mb-4">
              <h5>Connect with us</h5>
              <a
                target="_blank"
                class="links design1"
                href="https://www.facebook.com/TDCPOfficial/"
              >
                <FaFacebookF />
              </a>

              {/* <a
                target="_blank"
                class="links design1"
                href="https://www.facebook.com/TDCPOfficial/"
              >
                <FaGooglePlusG />
              </a> */}

              <a
                target="_blank"
                class="links design1"
                href="https://twitter.com/TDCPOFFICIAL"
              >
                <FaTwitter />
              </a>

              <a
                target="_blank"
                class="links design1"
                href="https://www.instagram.com/tdcp_official/"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="subcontainer d-flex justify-content-between">
        <div className="text-center text-lg-start">
          <p className="footer-info">
            Copyright &copy; 2023 Sikh Yatra Tours. All Rights Reserved
          </p>
        </div>
        <div className="text-center text-lg-end mt-2 mt-lg-0">
          <p className="footer-info-1">
            Tourism Development Corporation of Punjab
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
