import React, { useState } from "react";
import Modal from "react-modal";
import { RxCross1 } from "react-icons/rx";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "60%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    overflow: "hidden",
  },
};
const Popup = () => {
  const [modalIsOpen, setIsOpen] = useState(true);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="pop_modal_header">
        <RxCross1 onClick={closeModal}>x</RxCross1>
      </div>
      <div className="pop_modal_boday">
        <h1 className="main_heading text-center">This tour includes</h1>
        <p className="modal_desc">
          5/4 Star Accommodation | Standard Transport | Security Services
        </p>
        <div className="image_grid">
          <div className="img_container">
            <img src="/room1.jpg" alt="roomn1" className="room_img" />
            <div className="img_title">5/4 Star Accommodation</div>
          </div>
          <div className="img_container">
            <img src="/room2.png" alt="roomn1" className="room_img" />
            <div className="img_title">Standard Transport</div>
          </div>
          <div className="img_container">
            <img src="/room3.jpg" alt="roomn1" className="room_img" />
            <div className="img_title">Security Services</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
