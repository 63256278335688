import React, { useEffect, useState } from "react";
import Image7 from "../assets/ticket.svg";
import { QuantitySelector } from "./hotel/QuantitySelector";
import { CURRENCY_CODE } from "../../cardsContent";

const PoliceSquad = ({ police, FinalBooking, setFinalBooking }) => {
  const [quantity, setParentQuantity]=useState(0);

//console.log(police);
const SelectPolice=(h)=>{
    // var selectedcarArray=FinalBooking.policeService_id?FinalBooking.policeService_id.split(','):[];
    // if(selectedcarArray.includes(h.id.toString())){
    //   selectedcarArray = selectedcarArray.filter(item => item.toString() !== h.id.toString());
    //   setFinalBooking({... FinalBooking, "policeService_id":selectedcarArray.toString()})
  
    // }else{
    //   selectedcarArray.push(h.id)
    //   setFinalBooking({... FinalBooking, "policeService_id":selectedcarArray.toString()})
    // }
    // //console.log(FinalBooking);
    
  var selectedcarArray=FinalBooking.policeService_id?FinalBooking.policeService_id.split(','):[];
  var selectedcarQuantity=FinalBooking.policeService_qty?FinalBooking.policeService_qty.split(','):[];

  if(selectedcarArray.includes(h.id.toString())){
      let  FselectedcarArray = [];
      let  FselectedcarQty = [];
        for(let i=0; i<selectedcarArray.length;i++){
          if(selectedcarArray[i].toString() !== h.id.toString()){
            FselectedcarArray.push(selectedcarArray[i]);
            FselectedcarQty.push(selectedcarQuantity[i]);
          }
        }
        setFinalBooking({... FinalBooking, 
          "policeService_id":FselectedcarArray.toString(), "policeService_qty":FselectedcarQty.toString()})
        setParentQuantity(0);
   
  }else{
    if(quantity>0){

    selectedcarArray.push(h.id);
    selectedcarQuantity.push(quantity);
    setFinalBooking({... FinalBooking, "policeService_id":selectedcarArray.toString(), "policeService_qty":selectedcarQuantity.toString()})
    setParentQuantity(0);
   }else{
      alert("Please select quantity.")
    }

  }
    }

  return (
    <div className="book-tour-container m_t_2 box_shadow">
    <div className="banner">
      <img src={Image7}></img>
      <span> Add Security Services</span>
    </div>
    <div className="police_booking">
    <div className="police_grid">
        {
            police.map((p, i)=>(
                <div className="police_car">
                  <div className="police_car_inner">
                  <span className="p_title">{p.category}</span>
                    <div className="p_desc">
                      {p.description}
                    </div>
                    <div className="p_price">{CURRENCY_CODE}{' '}{p.price}/Day</div>
               
                  </div>
                  <QuantitySelector setParentQuantity={setParentQuantity}/>
                  <div className={FinalBooking.policeService_id?FinalBooking.policeService_id.includes(p.id)?"selected_car":"select-car":"select-car"}>
                            <button onClick={()=>SelectPolice(p)}>
                              Select 
                            </button>
                          </div>
               
                </div>
            ))
        }
    </div>
     
    </div>
  </div>
  )
}

export default PoliceSquad
