import { useEffect, useState } from "react";

export const QuantitySelector = ({ setParentQuantity }) => {
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setParentQuantity(quantity);
  }, [quantity]);
  return (
    <div className="quantity_car d-flex qty">
      <span
        className="room-minus"
        onClick={() => {
          setQuantity((prev) => (prev > 0 ? prev - 1 : 0));
        }}
      >
        -
      </span>
      <input
        type="text"
        className="room-count-input"
        name="room_count"
        disabled={true}
        value={quantity}
      />
      <span
        className="room-plus"
        onClick={() => {
          setQuantity((prev) => prev + 1);
        }}
      >
        +
      </span>
    </div>
  );
};
