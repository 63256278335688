import React, { useEffect } from "react";
import Image1 from "../../assets/gallery-img-5.png";
import Image2 from "../../assets/Picture6.png";
import Image3 from "../../assets/Picture2.png";
import Image4 from "../../assets/Picture3.png";
import Image5 from "../../assets/Gurdwara-Sahib-Clipart.png";
import Image6 from "../../assets/about.png";
import Image7 from "../../assets/Picture5.png";
import Image8 from "../../assets/Picture7.png";
import "../carousel/carousel.css";

function CustomSliders() {
  return (
    <div className="slider_container-1">
      <div className="row gallery-first-row">
        <div className="col-3">
          <img src={Image1} alt="travel and tours"></img>
        </div>
        <div className="col-3">
          <img src={Image2} alt="travel and tours"></img>
        </div>
        <div className="col-3">
          <img src={Image3} alt="travel and tours"></img>
        </div>
        <div className="col-3">
          <img src={Image4} alt="travel and tours"></img>
        </div>
      </div>
      <div className="row gallery-second-row">
        <div className="col-3">
          <img src={Image5} alt="travel and tours"></img>
        </div>
        <div className="col-3">
          <img src={Image6} alt="travel and tours"></img>
        </div>
        <div className="col-3">
          <img src={Image7} alt="travel and tours"></img>
        </div>
        <div className="col-3">
          <img src={Image8} alt="travel and tours"></img>
        </div>
      </div>
    </div>
  );
}

export default CustomSliders;
