import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getPost } from "../../server";
import Header from "../common/header";
import BannerSecond from "../common/BannerSecond";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
function Post() {
  //// const location = useLocation();
  const { id } = useParams();
  const [post, setPost] = useState([]);
  console.log(id);
  useEffect(() => {
    getPost(id).then((data) => setPost(data.data));
  }, []);
  return (
    <div>
      <Header />

      {post.map((p) => {
        return (
          <>
            <Helmet>
              {/* Standard metadata tags */}
              <title>{"pakistan tour packages"}</title>
              <meta name="description" content={p.meta_description} />
              {/* End standard metadata tags */}
              {/* Facebook tags */}
              <meta property="og:type" content={p.meta_title} />
              <meta property="og:title" content={p.meta_title} />
              <meta property="og:description" content={p.meta_description} />
              {/* End Facebook tags */}
              {/* Twitter tags */}
              <meta name="twitter:creator" content={p.user} />
              <meta name="twitter:card" content={p.meta_title} />
              <meta name="twitter:title" content={p.meta_title} />
              <meta name="twitter:description" content={p.meta_description} />
              {/* End Twitter tags */}
            </Helmet>
            {/* <BannerSecond subTitle={""} title={p.meta_title} /> */}
            <section className="main_container">
              <div className="inner_container">
                <div className="temple_blog">
                  <div className="temple_blog">
                    <div className="blog_img">
                      {/* /  <img src={p.image} alt={p.meta_title} /> */}
                    </div>
                    <h1 className="main_heading">{p.article_title}</h1>
                    <p>Author {p.user}</p>
                    <p
                      className="notbold"
                      dangerouslySetInnerHTML={{ __html: p.article_content }}
                    ></p>
                  </div>
                </div>
              </div>
            </section>
          </>
        );
      })}
    </div>
  );
}

export default Post;
