import axios from "axios";
import xml2js from "xml2js";

// Define your API base URL
const API_BASE_URL = "https://tdcp.gop.pk/sikyatra_api";
const API_BASE_URL_BOOKINGWHIZZ =
  "https://apiconnect.bookingwhizz.com/Connect.svc/xml";
// to get Blogs
export async function getBlogs() {
  try {
    const url = API_BASE_URL + "/get_Blogs";
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}
// To Get Post
export async function getPost(id) {
  try {
    const url = API_BASE_URL + "/get_Blogsbyid?id=" + id;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}
// to get tours
export async function getTours() {
  try {
    const url = API_BASE_URL + "/get_tours";
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function toCreateBooking(data) {
  try {
    const url = API_BASE_URL + "/save_booking";
    const response = await axios.post(url, JSON.stringify(data));
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function toSendSms(data) {
  try {
    const url = "https://tdcp.gop.pk/apis/sendEmail";
    const response = await axios.post(url, JSON.stringify(data));
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const generateSessionId = async (id, total, dollar) => {
  var tot = 0;
  try {
    //console.log(total * dollar);
    let totalPKR = total * dollar;
    const url =
      API_BASE_URL +
      "/CreateSessionId?orderId=" +
      id +
      "&total=" +
      Math.trunc(totalPKR);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log("Error:", error);
    console.log("Response data:", error.response);
  }
};
export const updateBooking = async (id, status) => {
  try {
    //console.log(id);
    const url = API_BASE_URL + "/update_booking?id=" + id + "&status=" + status;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log("Error:", error);
    console.log("Response data:", error.response);
  }
};

export async function getRoomsDetail(accommodationid, checkIn, checkOut) {
  try {
    const url =
      API_BASE_URL_BOOKINGWHIZZ +
      "/getavailability?userid=10002&password=YGiDp9ex0022019&accommodationid=" +
      accommodationid +
      "&checkin=" +
      checkIn +
      "&checkout=" +
      checkOut +
      "&multilanguageid=1";
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    //console.log(result);
    return result["Success"] ? result["Success"]["Result"] : [];
  } catch (error) {
    throw error;
  }
}

export async function getBookingDetails(id) {
  try {
    const url = API_BASE_URL + "/get_booking?booking_id=" + id;
    const response = await axios.get(url);
    //console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPackageDetails(dayId, tourID) {
  try {
    const url = API_BASE_URL + "/get_packages?day=" + dayId + "&tour=" + tourID;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to make a GET request
export async function fetchHotels(checkin, checkout, rating) {
  try {
    const url =
      API_BASE_URL_BOOKINGWHIZZ +
      "/getaccommodationsearchtest?userid=10018&password=LK67G5F0IW&accommodationids=&agentid=18&cityname=Lahore&checkin=" +
      checkin +
      "&checkout=" +
      checkout +
      "&limits=&offset=0&Sortby=2&Sort=1&&multilanguageid=1&fullbook=1&rooms=&adults=&pricerangestart=&pricerangeend=&ratings=" +
      rating +
      "&userrating=&propertycategory=&converted_currency=USD";
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    return result["Success"]
      ? result["Success"]["result"].filter((h) => h["MinRate"][0] !== "")
      : [];
  } catch (error) {
    throw error;
  }
}

async function parseXml(xmlData) {
  return new Promise((resolve, reject) => {
    xml2js.parseString(xmlData, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
}

export const CurrencyConversionFn = async () => {
  var url =
    API_BASE_URL_BOOKINGWHIZZ +
    "/conversionrate?userid=10002&password=YGiDp9ex0022019&currencycode=PKR";
  let responce = await axios.get(url);
  let data = await parseXml(responce.data);
  // console.log(data);
  data = data["Success"]["Result"].find(
    (d) => d["ToCurrencyCode"] == "USD" && d["FromCurrencyCode"] == "PKR"
  );
  console.log(data);
  return data;
};
export const CurrencyConversionPkrFn = async () => {
  var url =
    API_BASE_URL_BOOKINGWHIZZ +
    "/conversionrate?userid=10002&password=YGiDp9ex0022019&currencycode=USD";
  let responce = await axios.get(url);
  let data = await parseXml(responce.data);
  console.log(data);
  data = data["Success"]["Result"].find(
    (d) => d["ToCurrencyCode"] == "PKR" && d["FromCurrencyCode"] == "USD"
  );
  let n = data.ConversionRate.join();
  console.log(data);
  return data;
};
