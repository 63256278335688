import React from 'react'
import Header from '../common/header'
import Form from './form/form'
import "../contact/contactpage.css"
import Footer from '../common/footer'
import BannerSecond from '../common/BannerSecond'

function ContactPage() {
  return (
    <div className='contact-page-container'>
        <Header/>
        {/* <Banner1/> */}
        <BannerSecond  title="CONTACT" subTitle="Feel Free to Ask Anything"/>
        <Form/>
        <Footer/>
    </div>
  )
}

export default ContactPage