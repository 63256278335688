export const CURRENCY_CODE = "USD";

export const Locations = [
  {
    id: 0,
    img: "/assets/Picture2.png",
    title: "GURDWARA SACHA SAUDA, FAROOQABAD",
    desc: "Sacha Sauda is a village near Chuharkana (now known as Farooqabad) in Punjab, Pakistan. This holy Gurdwara stands massively in the fields on the other side.",
  },
  {
    id: 1,
    img: "/assets/Picture3.png",
    title: "GURDWARA RORI SAHIB, EMINABAD",
    desc: "In the old town of Eminabad, some 15 kms away from Gujranwala , Gurdwara Rori Sahib is located at the site where Guru Nanak is believed to have taken refuge from the pillage of Eminabad by Babur.",
  },
  {
    id: 2,
    img: "/assets/Picture4.png",
    title: "GURDWARA DARBAR SAHIB, KARTARPUR",
    desc: "Located in Kartarpur in the Narowal district of Punjab in Pakistan, it is one of the most important sites of Sikhism along with the Golden Temple and Janam Asthan Sahib.",
  },
  {
    id: 3,
    img: "/assets/Picture5.png",
    title: "GURDWARA PANJA SAHIB, HASAN ABDAL",
    desc: "Located in Hasan Abdal, near the city of Islamabad, the 19th-century Gurdwara attracts thousands of pilgrims every year. It is of particular importance to Sikhs because of the legends associated with it.",
  },
  {
    id: 4,
    img: "/assets/Picture6.png",
    title: "GURDWARA DERA SAHIB, LAHORE",
    desc: "Built at the site of the martyrdom of the 5th Sikh Guru, Guru Arjan Dev, this gurdwara is located just outside the walled city of Lahore. It is a part of an ensemble of historical monuments in Lahore, along with the Lahore Fort.",
  },
  {
    id: 5,
    img: "/assets/Tomb_of_Ranjit_Singh,_Lahore.jpg",
    title: "SAMADHI OF MAHARAJA RANJIT SINGH",
    desc: "Ranjit Singh is famous as Sher-e- Punjab in the history of the subcontinent. He ruled over Punjab from 1799 to 1839 AD. This was built at the place where Sher e Punjab Maharaja Ranjit was cremated.",
  },
];

export const amenitiesContent = [
  {
    img: "/assets/hotel.svg",
    title: "STAR ACCOMODATION",
  },
  {
    img: "/assets/breakfast.svg",
    title: "BREAKFAST AND DINNER",
  },

  {
    img: "/assets/security-guard.svg",
    title: "SECURITY",
  },
  {
    title: "TRANSPORT",
    img: "/assets/Path11.svg",
  },
];

export const featuresToursContent = [
  {
    img: "/assets/activity-icon-1.png",
    text: "Shopping Oppurtunities.",
  },
  {
    img: "/assets/activity-icon-1.png",
    text: "Sight Seeing Through Double Decker Service.",
  },
  {
    img: "/assets/activity-icon-2.png",
    text: "Sight Seeing Through Double Decker Service.",
  },
  {
    img: "/assets/activity-icon-4.png",
    text: "Stay in 4-star/5-star hotel including breakfast,",
  },
  {
    img: "/assets/What-We-Believe-icon-1.png",
    text: "Professional Tourist Guide.",
  },
  {
    img: "/assets/activity-icon-1.png",
    text: "Some quick example text to build on the card title and make up the bulk of the card's content.",
  },
];

export const hotelsContent = [
  {
    id: 0,
    img: "/assets/Hotel-One.png",
    title: "TDCP RESORT NANKANA",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
  {
    id: 1,
    img: "/assets/pearlcontinentallahore.png",
    title: "PC HOTEL, LAHORE",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
  {
    id: 2,
    img: "/assets/avari-hotel-lahore.png",
    title: "AVARI HOTEL, LAHORE",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
  {
    id: 3,
    img: "/assets/avari-hotel-lahore.png",
    title: "TDCP RESORT NANKANA",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
];

export const cancellationPolicy = [
  "7 Days before the departure, a 50% deduction",
  "3 Days before the departure, a 75% deduction",
  "Less than 3 Days before the departure, 100% deduction",
  "No amount will be refunded if any person leaves the trip at any stage",
];

export const visaPolicy = [
  // "The Foreign Office (Islamabad) may be requested to establish the “Sikh Facilitation Desk” in Lahore for Sikh Yatris from Canada, UK, & US.",
  // "Integrate the option to book the “Visa process directly through the TDCP website” and facilitate incorporation on the platform as well.",
  "TDCP does not cater Visa Services.",
];
export const terms = [
  "Please bring your National Identity Card (CNIC) and Original Passport along with you (mandatory). The TDCP reserves the right to refuse any participant, who does not have CNIC, to board the trip without any refund.",
  "The TDCP reserves the right to substantially change, alter or modify the trip schedule without any prior intimation to participants and/or costs due to unforeseen factors including without limitation traffic jams, landslides, riots, terrorist activity, political turmoil, bad weather or as deemed fit to be in the best interest of the whole group by the Company.",
  "You warrant and affirm that you will not get involved in any character failing activity, including but not limited to the use of obscene language or as the Company or its representative(s) deems fit, during the trip and acknowledge that failure to abide by this provision will entitle the TDCP to drop you off from rest of the trip without any refund.",
  "Please take care of the other participants’ respect and privacy. The TDCP and its representative(s) reserve the right to remove any person from the trip without warning in case of non-compliance with this condition.",
  "If, for any unforeseen reason or activity that is beyond the control of the TDCP, the trip is canceled at any time before departure then participants will be entitled to a refund of their respective amount paid to the TDCP after 25% deductions from the amount paid and/or charging any administrative costs incurred to make such refunds.",
  "You warrant and affirm that you do not possess any explosives, weapons, or prohibited drugs of any sort throughout the trip. The TDCP and its representative(s) shall not be held liable for any situation that arises due to your malafide or negligence and you shall hold the TDCP and its representative(s) harmless and indemnified from any legal or civil consequences arising out of your actions in this regard. Any participant found to be in possession of the aforementioned restricted article(s) shall be immediately dropped from the trip without any refund.",
  "In case of theft, robbery, or loss or damage by any means whatsoever, to the personal belongings of participants, regardless of their monetary value, during the trip, the TDCP or its representative(s) shall not be held liable for any such loss or damage be it direct or indirect.",
  "You warrant and affirm that you will use/handle any of the Company’s equipment provided to you during the course of this trip will utmost reasonable skill and care and warrant to not damage the Company’s property/equipment and/or any service tool under the possession of TDCP being used for the trip. If you fail to adhere to this condition, either intentionally or negligently, you shall be bound to pay/reimburse/indemnify the TDCP for its losses and any consequential damages that may arise from your failure to comply with this condition.",
  "The TDCP will take all reasonably possible measures to ensure the safety and well-being of participants of the trip, however, in case any unwanted situation arises which is beyond the reasonable control of the TDCP or its representative(s) and in the presence of any unforeseeable, inevitable event, the TDCP and/or its representative(s) shall, by any reason whatsoever, not be held liable and responsible for any consequences.",
];

export const includesData = [
  "Stay in a 4-star/5-star hotel",
  "Breakfast & Dinner",
  "Various Executive transport options.",
  "Professional Tourist Guide.",
  "Punjab Police Security throughout the tour (Cost to be added)",
  "Sightseeing double-decker bus Lahore/Rawalpindi & Islamabad major tourist attractions.",
  "Shopping opportunities",
  "Traditional food/ vegetarian cuisine",
  "Various cultural activities",
  "Souvenirs/ Gift hampers.",
];
