import React from "react";
import Image from "../../assets/template.png";
import "../goldentemple/goldentemple.css";

function Goldentemple({ showNankanaRow = true }) {
  return (
    <div className="temple-container">
      <section className="main_container">
        <div className="inner_container">
          <div className="temple_grid">
            <div className="grid_item_1">
              {/* <img src={Image} alt="Golden Temple" /> */}
            </div>
            <div className="grid_item_2 temple-content">
              <p>The Sikh Shrines</p>
              <h1 className="main_heading">SIKH GURDWARAS</h1>
              <p className="notbold m_t_2">
                There are around 195 Gurdwaras across Pakistan. While Gurdwara
                Janam Asthan, the birthplace of Guru Nanak Ji, remains the main
                focus for pilgrims, over the past few years, several smaller
                gurdwaras that had been in ruins for decades have been
                renovated. Located approximately 65kms from Lahore, Gurudwara
                Janam Asthan Sahib is one of the most revered places. Nankana
                Sahib after Guru Nanak Dev, the founder of Sikhism, who was born
                there.
              </p>
            </div>
          </div>
        </div>
      </section>

      {showNankanaRow && (
        <section className="main_container nankana">
          <div className="inner_container">
            <h1 className="main_heading">
              Gurdwara Janam Asthan Sahib, Nankana Sahib
            </h1>

            <div className="w_50">
              <p className="m_t_2">
                Located approximately 65kms from Lahore, Gurudwara Janam Asthan
                Sahib is one of the most revered places for Sikhs from all over
                the world.{" "}
              </p>
              <p className="mb-0 m_t_2">
                Previously known as Rai-BhoiDi-Talwandi, the town was renamed
                Nankana Sahib after Guru Nanak Dev, the founder of Sikhism, who
                was born there.
              </p>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Goldentemple;
