import React, { useEffect, useState } from 'react'
import BannerSecond from '../../common/BannerSecond'
import Header from '../../common/header'
import Footer from '../../common/footer'
import Image from "../../assets/ticket.svg";
import {ReactComponent as Tick} from'../tick.svg';
import {ReactComponent as Visa} from'../visa.svg';
import {updateBooking, getBookingDetails, toSendSms } from '../../../server';
import { useLocation } from 'react-router-dom';
import EmailTemplate from './EmailTemplate';
import ReactDOMServer from 'react-dom/server';
import { CURRENCY_CODE } from '../../../cardsContent';

const ThankYou = () => {
    const location = useLocation();
    const [BookingData, setBookingData]=useState('');
    const queryParams = new URLSearchParams(location.search);
    const  bookingId  = queryParams.get("bookingId");

    const getData= async(bookingId)=>{
     // const udata=await getBookingDetails(bookingId, 'confirmed');
      const udata=await updateBooking(bookingId, 'confirmed');
      if(udata['status']==200){
        const data=await getBookingDetails(bookingId);
        //console.log("data=>",data);
        if(data['status']==200){
          setBookingData(data['data'][0]);
          const jsxString = ReactDOMServer.renderToString(<EmailTemplate data={data['data'][0]} />);
        console.log(jsxString);
          var Emaildata={
            "email": data['data'][0]['members'][0]["email"],
            "subject": "Reservation Confirmation",
            "body": encodeURIComponent(jsxString)
          }
          const EmailREsponce=await toSendSms(Emaildata)
      
        }else{
          setBookingData([]);
        }
      }
    }
    useEffect(()=>{
      //console.log('inn')
        getData(bookingId);
    }, []);

  return (
    <>
      {/* <div dangerouslySetInnerHTML={{ __html: jsxOutput }} /> */}

<Header />

  <BannerSecond title="THANK YOU FOR YOUR BOOKING" subTitle="Booking Successful" />
  <section className='main_container'>

    <div className="inner_container">
        {
            BookingData !==''?
            <div className="book-tour-container box_shadow">
            <div className="banner">
              <img src={Image}></img>
              <span> Booking Details</span>
            </div>
            <div className="hotel-booking">
              <div className="ticl_container">
                  <div className="tick me-3">
                    <Tick />
                  </div>
                  <div className="tick_desc">
                      <div>Booking # {BookingData.id}</div>
                      <div className='thank_you_title mb-0'>THANK YOU, {BookingData['members'].length?BookingData['members'][0]['fullName']:null}</div>
                  </div>
              </div>
      
              <div className="thank_you_cards">
              <div className="thank_you_title">
              Your Booking is Confirmed
               </div>
               <div className="thank_you_desc">
               You purchased {BookingData['tour'][0]['title']} Package for Group of {BookingData['members'].length} persons...
               </div>
               <div className="thank_you_desc">
               You'll receive a confirmation email shortly
                        </div>
              </div>
              <div className="thank_you_cards">
                  <div className='thank_you_main_title'>
                  Booking Details
                      </div>
              <div className="thank_you_title">
              Contact Information (Group Leader)
               </div>
               <div className="thank_you_desc">
               {BookingData['members'][0]['fullName']}
               </div>
               <div className="thank_you_desc">
               {BookingData['members'][0]['email']}
 /                {BookingData['members'][0]['phone']}

              </div>
              <div className="thank_you_desc">
              {BookingData['members'].length} Persons Group        </div>
              </div>
              <div className="thank_you_cards">
          
              <div className="thank_you_title">
              Payment Method
            </div>
               <div className="thank_you_desc">
               <Visa className='me-2'/> {BookingData.payment_method}
               </div>
      
              </div>
              <div className="thank_you_total">
                      <div>GRAND TOTAL </div>
                      <div>{CURRENCY_CODE}.{Number(BookingData.total).toLocaleString()}</div>
              </div>
             
            </div>
          </div>
            :null
        }

    </div>

  </section>
  <Footer/>
    </>

  )
}

export default ThankYou
