import React from "react";
import Header from "../common/header";
import Banner from "../home/banner/banner";
import Goldentemple from "../home/goldentemple/goldentemple";
import "../home/homepage.css";
import Card from "./card/card";
import TourPackage from "./tourpackage/tourpackage";
import CustomSliders from "./feature/CustomSliders";
import AboutSikh from "./aboutsikh/aboutsikh";
import Footer from "../common/footer";
import { Helmet } from "react-helmet";
function Homepage() {
  return (
    <div className="home-container">
      <Helmet>
        {/* Standard metadata tags */}
        <title>{"pakistan tour packages"}</title>
        <meta name="description" content={"pakistan tour packages"} />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content={"pakistan tour packages"} />
        <meta property="og:title" content={"pakistan tour packages"} />
        <meta property="og:description" content={"pakistan tour packages"} />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta name="twitter:creator" content={"pakistan tour packages"} />
        <meta name="twitter:card" content={"pakistan tour packages"} />
        <meta name="twitter:title" content={"pakistan tour packages"} />
        <meta name="twitter:description" content={"pakistan tour packages"} />
        {/* End Twitter tags */}
      </Helmet>

      <Header />
      <Banner />
      <Goldentemple />
      <Card />
      <TourPackage />
      <CustomSliders />
      <AboutSikh />
      <Footer />
    </div>
  );
}

export default Homepage;
