import React from "react";
import "./banner.css";
import { HashLink } from "react-router-hash-link";

function Banner() {
  return (
    <div className="banner-container text-center">
      <div className="banner-content">
        <h1>Jee Aayan Nu!</h1>
        <h1>ਜੀ ਆਇਆਂ ਨੂੰ!</h1>
        <p>
          First Ever Opportunity by a Reliable Organisation Offering Safe and
          Secure Yatra.
        </p>
        <HashLink to="#packages">
          <button type="Submit">See Packages</button>
        </HashLink>
      </div>
    </div>
  );
}

export default Banner;
